import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import apiAxios from './services/axios'
import rules from "./plugins/rules";

//Make Axios global
Vue.prototype.$Axios = apiAxios
Vue.prototype.$rules = rules;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
