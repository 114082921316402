const rules = {
  REQUIRED: (param, value) => {
    if (value || value === 0) {
      return true;
    } else {
      return `${param} is Required`;
    }
  },
  NUMBER: (param, value, options) => {
    if (options?.rejectZero && (value === 0 || value === "0")) {
      return `${param} should be greater than zero`;
    } else if (!options?.rejectZero && (value === 0 || value === "0")) {
      return true;
    } else if (value && !isNaN(value)) {
      return true;
    } else {
      return `${param} should be a number`;
    }
  },
  IS_URL: (param, value) => {
    const pattern =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
    if (pattern.test(value)) {
      return true;
    } else {
      return `${param} should be a valid URL`;
    }
  },
};

export default rules;
