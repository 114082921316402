import store from '@/store'

//Redirect paths authentifcated
export const AuthRequired = function (to, from, next) {
  let token = store.getters.token
  if (token) {
    next()
  } else {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    next('/')
  }
}

//Redirect paths not authentifcated
export const NotAuthRequired = function (to, from, next) {
  let token = store.getters.token
  if (!token) {
    next()
  } else {
    next('/overview/');
  }
}